import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Card, Badge, Spinner, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { CancelTransaction, GetTransactionsOfUser } from "../../../network/apis/transactions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatDate, formatPrice } from "../../../utils/Utils";
import { post } from "../../../network/Config/apiService";

const Payments = () => {
  const { user } = useSelector((state) => state.user);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPayments = async (userId) => {
    setLoading(true);
    try {
      const res = await GetTransactionsOfUser(userId);
      if (res.result === "success") {
        const sortedTransactions = res.transactions.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

        setPayments(sortedTransactions);
      } else {
        toast.error(res?.message ?? "Error fetching payments");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message ?? "Error fetching payments");
    }
  };

  const cancelPayment = async (paymentId) => {
    try {
      const res = await CancelTransaction(paymentId);
      if (res.result === "success") {
        toast.success("Payment canceled successfully");
        fetchPayments(user.uid);
      } else {
        toast.error(res?.message ?? "Error canceling payment");
      }
    } catch (error) {
      toast.error(error?.message ?? "Error canceling payment");
    }
  };

  const retryPayment = async (paymentId) => {
    post(`/payment/regenerate-request`, {
      uid: user.uid,
      callbackUrl: `${window.location.origin}/pricing`,
      paymentId: paymentId,
    })
      .then((response) => {
        window.location.href = response.data.payment_url;
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message ?? "Something went wrong. Please try again later.");
      });
  };

  useEffect(() => {
    if (user?.uid) {
      fetchPayments(user.uid);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Head title="Payment History"></Head>
      <Content>
        <BlockHead size="lg">
          <div className="nk-block-head-sub">
            <span>Account Billing</span>
          </div>
          <BlockBetween size="md" className="g-4">
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Payment History
              </BlockTitle>
              <BlockDes>
                <p>Here is your payment history of account.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            {loading ? (
              <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
                <Spinner size="lg" color="primary" />
              </section>
            ) : (
              <table className="table table-tranx">
                <thead>
                  <tr className="tb-tnx-head">
                    <th className="tb-tnx-id">
                      <span className="">ID</span>
                    </th>
                    <th className="tb-tnx-info">
                      <span className="tb-tnx-desc d-none d-sm-inline-block">
                        <span>Plan</span>
                      </span>
                      <span className="tb-tnx-date d-md-inline-block d-none">
                        <span className="">Date</span>
                      </span>
                    </th>
                    <th className="tb-tnx-amount is-alt">
                      <span className="tb-tnx-total">Total</span>
                      <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                    </th>
                    <th className="tb-tnx-action">
                      <span>&nbsp;</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.length > 0 && (
                    <>
                      {payments?.map((item) => {
                        return (
                          <tr key={item.paymentId} className="tb-tnx-item">
                            <td className="tb-tnx-id">
                              <a
                                href="#ref"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>{item.paymentId}</span>
                              </a>
                            </td>
                            <td className="tb-tnx-info">
                              <div className="tb-tnx-desc">
                                <span
                                  className="title"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.description ?? "N/A"}
                                </span>
                              </div>
                              <div className="tb-tnx-date">
                                <span
                                  className="date"
                                  style={{
                                    minWidth: "90px",
                                  }}
                                >
                                  {formatDate(item.date, "DD MMM YYYY")}
                                </span>
                              </div>
                            </td>
                            <td className="tb-tnx-amount is-alt">
                              <div className="tb-tnx-total">
                                <span className="amount">
                                  {formatPrice({
                                    currency: item?.planData[0]?.currency ?? item?.planData?.currency,
                                    amount: item?.amountToShow,
                                  })}
                                </span>
                              </div>
                              <div className="tb-tnx-status">
                                <Badge
                                  className="badge-dot"
                                  color={
                                    item.status === "paid"
                                      ? "success"
                                      : item.status === "pending"
                                      ? "warning"
                                      : "danger"
                                  }
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.status}
                                </Badge>
                              </div>
                            </td>
                            <td className="tb-tnx-action">
                              {item?.status === "pending" && (
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-plain">
                                      <li>
                                        <DropdownItem
                                          tag="button"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            retryPayment(item.paymentId);
                                          }}
                                        >
                                          Retry
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#delete"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            cancelPayment(item.paymentId);
                                          }}
                                        >
                                          Cancel
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Payments;
