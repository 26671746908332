import React from "react";
import { Card, Col, Row } from "reactstrap";
import { Button, RSelect } from "../Component";
import { useDispatch } from "react-redux";
import { removeItemFromCart, updateItem } from "../../redux/slices/CartSlice";
import { formatPrice } from "../../utils/Utils";

function CartItem({ cartItem }) {
  const dispatch = useDispatch();
  const isRenew = cartItem?.isRenew ?? false;
  const isUpgrade = cartItem?.isUpgrade ?? false;

  const updateCartItem = ({ id, isBotIncluded, duration }) => {
    const updatedItem = {
      ...cartItem,
      isBotIncluded: isBotIncluded,
      duration,
    };

    dispatch(
      updateItem({
        id: id,
        item: updatedItem,
      })
    );
  };

  const getDiscountedPrice = ({ price, duration }) => {
    const totalPrice = (price ?? 0) * (duration ?? 1);

    if (cartItem?.enableDiscount && cartItem?.discount && cartItem?.discount?.threeMonths > 0 && duration === 3) {
      return (totalPrice - (totalPrice * cartItem?.discount?.threeMonths) / 100).toFixed(2);
    } else if (cartItem?.enableDiscount && cartItem?.discount && cartItem?.discount?.sixMonths > 0 && duration === 6) {
      return (totalPrice - (totalPrice * cartItem?.discount?.sixMonths) / 100).toFixed(2);
    } else if (cartItem?.enableDiscount && cartItem?.discount && cartItem?.discount?.yearly > 0 && duration === 12) {
      return (totalPrice - (totalPrice * cartItem?.discount?.yearly) / 100).toFixed(2);
    } else {
      return totalPrice;
    }
  };

  return (
    <section>
      <Card className="card-bordered sp-plan my-2">
        <Row className="no-gutters">
          <Col md="8">
            <div className="sp-plan-info card-inner">
              <Row className="gx-0 gy-3">
                <Col sm="12">
                  <div className="sp-plan-name">
                    <h6 className="title">
                      {cartItem.title} {isRenew ? "(Renew)" : isUpgrade ? "(Upgrade)" : ""}
                    </h6>
                    <p>{cartItem.description}</p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sp-plan-desc card-inner">
              <Row className="gx-1">
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Monthly Price </span>
                    {formatPrice({
                      currency: cartItem?.currency,
                      amount:
                        cartItem?.currency === "IRR"
                          ? cartItem?.isBotIncluded
                            ? cartItem?.withBotTomansPrice
                            : cartItem?.withoutBotTomansPrice
                          : cartItem?.isBotIncluded
                          ? cartItem?.withBotUsdPrice
                          : cartItem?.withoutBotUsdPrice,
                    })}
                  </p>
                </Col>
                {cartItem?.isBotOptionAvailable && !isRenew && !isUpgrade && (
                  <Col col="6" lg="3">
                    <section>
                      <span className="text-soft">Strategy Bot Access</span>
                      <RSelect
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },
                        ]}
                        onChange={(selectedOption) => {
                          updateCartItem({
                            id: cartItem.id,
                            isBotIncluded: selectedOption.value === "yes" ? true : false,
                            duration: cartItem?.duration ?? 1,
                          });
                        }}
                        defaultValue={[
                          {
                            value: cartItem?.isBotIncluded ? "yes" : "no",
                            label: cartItem?.isBotIncluded ? "Yes" : "No",
                          },
                        ]}
                        placeholder="Bot Access"
                      />
                    </section>
                  </Col>
                )}
                {!isRenew && !isUpgrade && (
                  <Col col="6" lg="4">
                    <span className="text-soft">Duration</span>
                    <RSelect
                      options={[
                        {
                          label: "1 Month",
                          value: 1,
                        },
                        {
                          label: (
                            <span>
                              3 Months{" "}
                              {cartItem?.enableDiscount && cartItem?.discount && cartItem?.discount?.threeMonths > 0 ? (
                                <span className="text-success">({cartItem?.discount?.threeMonths}% off)</span>
                              ) : (
                                ""
                              )}
                            </span>
                          ),
                          value: 3,
                        },
                        {
                          label: (
                            <span>
                              6 Months{" "}
                              {cartItem?.enableDiscount && cartItem?.discount && cartItem?.discount?.sixMonths > 0 ? (
                                <span className="text-success">({cartItem?.discount?.sixMonths}% off)</span>
                              ) : (
                                ""
                              )}
                            </span>
                          ),
                          value: 6,
                        },
                        {
                          label: (
                            <span>
                              12 Months{" "}
                              {cartItem?.enableDiscount && cartItem?.discount && cartItem?.discount?.yearly > 0 ? (
                                <span className="text-success">({cartItem?.discount?.yearly}% off)</span>
                              ) : (
                                ""
                              )}
                            </span>
                          ),
                          value: 12,
                        },
                      ]}
                      onChange={(selectedOption) => {
                        updateCartItem({
                          id: cartItem.id,
                          isBotIncluded: cartItem.isBotIncluded,
                          duration: selectedOption?.value ?? 1,
                        });
                      }}
                      defaultValue={
                        cartItem.duration
                          ? [
                              {
                                label: `${cartItem.duration} ${cartItem.duration > 1 ? "Months" : "Month"}`,
                                value: cartItem.duration,
                              },
                            ]
                          : []
                      }
                      placeholder="Duration"
                    />
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col md="4">
            <div className="sp-plan-action card-inner">
              <h6 className="title">
                Total Price:{" "}
                <span className="mx-1">
                  {isRenew || isUpgrade
                    ? formatPrice({
                        currency: cartItem?.currency,
                        amount: cartItem?.currency === "IRR" ? cartItem?.priceInIRR : cartItem?.priceInUSD,
                      })
                    : formatPrice({
                        currency: cartItem?.currency,
                        amount: getDiscountedPrice({
                          duration: cartItem?.duration ?? 1,
                          price:
                            cartItem?.currency === "IRR"
                              ? cartItem?.isBotIncluded
                                ? cartItem?.withBotTomansPrice
                                : cartItem?.withoutBotTomansPrice
                              : cartItem?.isBotIncluded
                              ? cartItem?.withBotUsdPrice
                              : cartItem?.withoutBotUsdPrice,
                        }),
                      })}
                </span>
              </h6>
              <div className="sp-plan-note text-md-center">
                <Button
                  className="btn btn-outline-danger"
                  onClick={() => {
                    dispatch(
                      removeItemFromCart({
                        id: cartItem.id,
                      })
                    );
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </section>
  );
}

export default CartItem;
